<template>
  <div>
    <profile-search-data-search />
  </div>
</template>

<script>
const ProfileSearchDataSearch = () => import('./ProfileSearchDataSearch.vue');

export default {
  name: 'ProfileSearchData',
  components: {
    ProfileSearchDataSearch,
  },
  props: {
    data: Object,
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
